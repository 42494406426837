import './ExpeditionPage.css'
import {useCallback, useState, useEffect} from "react"
import { ethers } from "ethers"
import Web3Modal from "web3modal"
import WalletLink from "walletlink"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { toast } from 'react-toastify'
import moment from 'moment'
import Countdown from 'react-countdown'
import {Link} from 'react-router-dom'
import utils from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ERC721Contract from '../../contracts/ERC721.json'
import CattoKatsuContract from '../../contracts/CattoKatsu.json'
import KatsuTokenContract from '../../contracts/KatsuToken.json'
import CattoAttributesContract from '../../contracts/CattoAttributes.json'
import CattoExpeditionContract from '../../contracts/CattoExpedition.json'

// https://codesandbox.io/s/95r4k?file=/pages/index.tsx:4526-4537

const encounterableMap = {
  '0xEa269ABCaC1219371A09E7A32D104AaBbC78dB1f': {
    name: 'Catto Katsu',
    type: '2',
  },
  // Mainnet
  '0x0000000000000000000000000000000000000000': {
    name: 'The Unknown',
    type: '0'
  },
  '0xDAC2031173c07BD622759051Df373b708A1acbcE': {
    name: 'Creatures Of The Cave',
    type: '2',
  },
  '0xb680b9861f73B30d86BdC248376d9566ade3FD35': {
    name: 'FantomSquad',
    type: '2',
  },
  '0xA3034DbEbB9826f6390FA4418417fca43A3B9a87': {
    name: 'Funky Tiger Mob',
    type: '1',
  },
  '0xA7C6F112B19277b02c55Bd52a76d7AF2Fb36f9f1': {
    name: 'RookieBears',
    type: '1',
  },
  '0x3C997415D8b8fadf919Ea6d5acc089Ad59a6B2e2': {
    name: 'FantomFrogs',
    type: '1',
  },
}

const rpcCfg = {}
rpcCfg[process.env.REACT_APP_CHAIN_ID] = process.env.REACT_APP_CHAIN_RPC

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: { rpc: rpcCfg },
  },
  'custom-walletlink': {
    display: {
      logo: '/img/icon-coinbase.jpeg',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet',
    },
    options: {
      appName: 'Coinbase',
      rpc: process.env.REACT_APP_CHAIN_RPC,
      chainId: process.env.REACT_APP_CHAIN_ID,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options
      const walletLink = new WalletLink({
        appName,
      })
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
      await provider.enable()
      return provider
    },
  },
}

let web3Modal

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
  })
}

function ExpeditionPage(callback, deps) {
  const [web3Provider, setWeb3Provider] = useState(null)
  const [signer, setSigner] = useState(null)
  const [signerAddress, setSignerAddress] = useState(null)
  const [networkID, setNetworkID] = useState(null)
  const [mintContract, setMintContract] = useState(null)
  const [tokenContract, setTokenContract] = useState(null)
  const [cattoAttributesContract, setCattoAttributesContract] = useState(null)
  const [cattoExpeditionContract, setCattoExpeditionContract] = useState(null)
  const [userCattos, setUserCattos] = useState([])
  const [expeditionCattos, setExpeditionCattos] = useState([])
  const [userTokens, setUserTokens] = useState(ethers.BigNumber.from(0))
  const [userTokenAirdrop, setUserTokenAirdrop] = useState(ethers.BigNumber.from(0))
  const [cattosAttributes, setCattosAttributes] = useState(null)
  const [cattosCurrentExpedition, setCattosCurrentExpedition] = useState({})
  const [selectedCatto, setSelectedCatto] = useState(null)
  const [currentAction, setCurrentAction] = useState('')
  const [isExpeditionApprovedInCatto, setIsExpeditionApprovedInCatto] = useState(false)
  const [isTokenApproved, setIsTokenApproved] = useState(false)
  const [isTransactionPending, setIsTransactionPending] = useState(false)
  
  const connectWallet = useCallback(async function () {
    let provider = null
    try {
      provider = await web3Modal.connect()
    } catch (err) {
      toast.warning('You have cancelled the connection attempt')
      return
    }
    const web3Provider = new ethers.providers.Web3Provider(provider)
    const signer = web3Provider.getSigner()
    const address = await signer.getAddress()
    const network = await web3Provider.getNetwork()
    setWeb3Provider(web3Provider)
    setSigner(signer)
    setSignerAddress(address)
    setNetworkID(String(network.chainId))
    setMintContract(new ethers.Contract(process.env.REACT_APP_MINT_CONTRACT, CattoKatsuContract.abi, signer))
    setTokenContract(new ethers.Contract(process.env.REACT_APP_TOKEN_CONTRACT, KatsuTokenContract.abi, signer))
    setCattoAttributesContract(new ethers.Contract(process.env.REACT_APP_ATTRIBUTES_CONTRACT, CattoAttributesContract.abi, signer))
    setCattoExpeditionContract(new ethers.Contract(process.env.REACT_APP_EXPEDITION_CONTRACT, CattoExpeditionContract.abi, signer))
  }, [])
  
  const disconnectWallet = useCallback(
    async function () {
      await web3Modal.clearCachedProvider()
      if (web3Provider?.disconnect && typeof web3Provider.disconnect === 'function') {
        await web3Provider.disconnect()
      }
      setWeb3Provider(null)
      setSigner(null)
      setSignerAddress(null)
      setNetworkID(null)
      setMintContract(null)
      setTokenContract(null)
      setCattoAttributesContract(null)
      setCattoExpeditionContract(null)
      setUserCattos([])
      setExpeditionCattos([])
      setUserTokens(ethers.BigNumber.from(0))
      setUserTokenAirdrop(ethers.BigNumber.from(0))
      setCattosAttributes(null)
      setSelectedCatto(null)
    },
    [web3Provider]
  )
  
  const switchToMainnet = useCallback(
    async function () {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
          chainId: process.env.REACT_APP_CHAIN_ID_HEX,
          rpcUrls: [process.env.REACT_APP_CHAIN_RPC],
          chainName: "Fantom",
          nativeCurrency: {
            name: "Fantom",
            symbol: "FTM",
            decimals: 18
          },
          blockExplorerUrls: ["https://ftmscan.com/"]
        }]
      });
    },
    []
  )
  
  const handleCattoSelect = useCallback(
    async function (catto) {
      setSelectedCatto(catto)
      if (!cattosCurrentExpedition[catto]) {
        const expedition = await cattoExpeditionContract.getLatestExpedition(catto)
        const newMap = { ...cattosCurrentExpedition }
        newMap[catto] = {
          id: expedition.id.toString(),
          cattoId: expedition.cattoId.toString(),
          endTimestamp: expedition.endTimestamp.toString(),
          earnedExp: expedition.earnedExp.toString(),
          earnedKatsu: ethers.utils.formatEther(expedition.earnedKatsu),
          encounterableAddr: expedition.encounterableAddr,
          encounterableId: expedition.encounterableId.toString(),
          encounterableLvl: expedition.encounterableLvl.toString(),
          mercenaryAddr: expedition.mercenaryAddr,
          mercenaryId: expedition.mercenaryId.toString(),
          mercenaryLvl: expedition.mercenaryLvl.toString(),
          exists: expedition.exists,
          hiredMercenary: expedition.hiredMercenary,
          isActive: expedition.isActive,
        }
        if (expedition.encounterableAddr !== ethers.constants.AddressZero) {
          const erc721 = new ethers.Contract(expedition.encounterableAddr, ERC721Contract.abi, signer)
          const tokenURI = await erc721.tokenURI(expedition.encounterableId)
          // const tokenMetaResp = await fetch('https://catto-katsu-testnet.s3.ap-southeast-1.amazonaws.com/metadata/0.json')
          let tokenMetaResp = null
          let tokenMeta = null
          try {
            tokenMetaResp = await fetch(utils.normaliseIpfsUrl(tokenURI))
            tokenMeta = await tokenMetaResp.json()
          } catch (err) {
            tokenMeta = {image: '/img/icon-launch-roadmap.png'}
          }
          newMap[catto].encounterableImg = tokenMeta.image
        }
        if (expedition.mercenaryAddr !== ethers.constants.AddressZero) {
          const erc721 = new ethers.Contract(expedition.mercenaryAddr, ERC721Contract.abi, signer)
          const tokenURI = await erc721.tokenURI(expedition.encounterableId)
          // const tokenMetaResp = await fetch('https://catto-katsu-testnet.s3.ap-southeast-1.amazonaws.com/metadata/0.json')
          let tokenMetaResp = null
          let tokenMeta = null
          try {
            tokenMetaResp = await fetch(utils.normaliseIpfsUrl(tokenURI))
            tokenMeta = await tokenMetaResp.json()
          } catch (err) {
            tokenMeta = {image: '/img/icon-launch-roadmap.png'}
          }
          newMap[catto].mercenaryImg = tokenMeta.image
        }
        // console.log(newMap)
        newMap[catto].encounterableType = encounterableMap[expedition.encounterableAddr].type
        setCattosCurrentExpedition(newMap)
      }
    },
    [cattoExpeditionContract, cattosCurrentExpedition, signer]
  )
  
  const claimAirdrop = useCallback(
    async function () {
      setIsTransactionPending(true)
      try {
        const tx = await tokenContract.claimAirdrop()
        await tx.wait()
        setUserTokenAirdrop(ethers.BigNumber.from(0))
        setUserTokens(userTokens.add(userTokenAirdrop))
        toast.success('$KATSU airdrop claimed successfully')
      } catch (err) {
        toast.error('Failed to claim token airdrop' + err.message)
      }
      setIsTransactionPending(false)
    },
    [tokenContract, userTokenAirdrop, userTokens]
  )
  
  const approveExpeditionInCatto = useCallback(
    async function () {
      setIsTransactionPending(true)
      try {
        const tx = await mintContract.setApprovalForAll(process.env.REACT_APP_EXPEDITION_CONTRACT, true)
        await tx.wait()
        setIsExpeditionApprovedInCatto(true)
        toast.success('Approval success')
      } catch (err) {
        toast.error('Failed to approve expedition contract in Catto' + err.message)
      }
      setIsTransactionPending(false)
    },
    [mintContract]
  )
  
  const approveKatsuToken = useCallback(
    async function () {
      setIsTransactionPending(true)
      try {
        const approved = await tokenContract.approve(process.env.REACT_APP_ATTRIBUTES_CONTRACT, ethers.constants.MaxUint256)
        await approved.wait()
        setIsTokenApproved(true)
        toast.success('Approval success')
      } catch (err) {
        toast.error('Failed to approve expedition contract in Catto' + err.message)
      }
      setIsTransactionPending(false)
    },
    [tokenContract]
  )
  
  const startExpedition = useCallback(
    async function () {
      if (!cattosCurrentExpedition[selectedCatto]) {
        toast.error('Failed to retrieve Catto expedition info, please refresh page')
        return
      }
      if (cattosCurrentExpedition[selectedCatto].isActive) {
        toast.warning('Catto is on an active expedition')
        return
      }
      setIsTransactionPending(true)
      try {
        const tx = await cattoExpeditionContract.startExpedition(selectedCatto, { gasLimit: 600000 })
        await tx.wait()
      } catch (err) {
        console.log(err)
        setIsTransactionPending(false)
        toast.error('Failed to start expedition')
        return
      }
      window.location.reload()
    },
    [cattoExpeditionContract, cattosCurrentExpedition, selectedCatto]
  )
  
  const endExpedition = useCallback(
    async function () {
      if (!cattosCurrentExpedition[selectedCatto]) {
        toast.error('Failed to retrieve Catto expedition info, please refresh page')
        return
      }
      if (!cattosCurrentExpedition[selectedCatto].isActive) {
        toast.warning('Catto expedition has previously ended, please refresh page')
        return
      }
      setIsTransactionPending(true)
      try {
        let estimatedGas = await cattoExpeditionContract.estimateGas.endExpedition(selectedCatto)
        estimatedGas = estimatedGas.add('50000').toString()
        const tx = await cattoExpeditionContract.endExpedition(selectedCatto, { gasLimit: estimatedGas })
        await tx.wait()
      } catch (err) {
        setIsTransactionPending(false)
        toast.error('Failed to end expedition: ' + err.message)
        return
      }
      window.location.reload()
    },
    [cattoExpeditionContract, cattosCurrentExpedition, selectedCatto]
  )
  
  const hireMercenary = useCallback(
    async function () {
      if (!cattosCurrentExpedition[selectedCatto]) {
        toast.error('Failed to retrieve Catto expedition info, please refresh page')
        return
      }
      if (!cattosCurrentExpedition[selectedCatto].isActive) {
        toast.warning('Catto expedition has previously ended, please refresh page')
        return
      }
      setIsTransactionPending(true)
      try {
        const tx = await cattoExpeditionContract.hireMercenary(selectedCatto)
        await tx.wait()
      } catch (err) {
        setIsTransactionPending(false)
        toast.error('Failed to hire mercenary: ' + err.message)
        return
      }
      window.location.reload()
    },
    [cattoExpeditionContract, cattosCurrentExpedition, selectedCatto]
  )
  
  const levelUp = useCallback(
    async function () {
      const attr = cattosAttributes[selectedCatto]
      if (!attr) {
        toast.error('Failed to retrieve Catto attributes info, please refresh page')
        return
      }
      setIsTransactionPending(true)
      try {
        const tx = await cattoAttributesContract.levelUp(selectedCatto)
        await tx.wait()
      } catch (err) {
        setIsTransactionPending(false)
        toast.error('Failed to level up: ' + err.message)
        return
      }
      window.location.reload()
    },
    [cattoAttributesContract, cattosAttributes, selectedCatto]
  )
  
  const handleActionIntent = useCallback(
    function (action) {
      setCurrentAction(action)
    }, []
  )
  
  const handleNameCatto = useCallback(
    async function () {
      let cattoName = document.getElementById('cattoName').value
      if (!cattoName) {
        toast.warning('Must not be empty')
        return
      }
      
      setIsTransactionPending(true)
  
      try {
        const tx = await cattoAttributesContract.nameCatto(selectedCatto, cattoName)
        await tx.wait()
      } catch (err) {
        setIsTransactionPending(false)
        toast.error('Failed to name Catto: ' + err.message)
        return
      }
      
      const newCattoAttr = {...cattosAttributes}
      newCattoAttr[selectedCatto].name = cattoName
      
      setCattosAttributes(newCattoAttr)
      setCurrentAction('')
      setIsTransactionPending(false)
    },
    [cattoAttributesContract, cattosAttributes, selectedCatto]
  )
  
  const handleBio = useCallback(
    async function () {
      let cattoBio = document.getElementById('cattoBio').value
      if (!cattoBio) {
        toast.warning('Must not be empty')
        return
      }
      
      setIsTransactionPending(true)
      
      try {
        const tx = await cattoAttributesContract.setBio(selectedCatto, cattoBio)
        await tx.wait()
      } catch (err) {
        setIsTransactionPending(false)
        toast.error('Failed to set Catto bio: ' + err.message)
        return
      }
  
      const newCattoAttr = {...cattosAttributes}
      newCattoAttr[selectedCatto].bio = cattoBio
  
      setCattosAttributes(newCattoAttr)
      setCurrentAction('')
      setIsTransactionPending(false)
    },
    [cattoAttributesContract, cattosAttributes, selectedCatto]
  )
  
  const handleAddLore = useCallback(
    async function () {
      let cattoLoreEntry = document.getElementById('cattoLoreEntry').value
      if (!cattoLoreEntry) {
        toast.warning('Must not be empty')
        return
      }
      
      setIsTransactionPending(true)
      
      try {
        const tx = await cattoAttributesContract.addCattoLore(selectedCatto, cattoLoreEntry)
        await tx.wait()
      } catch (err) {
        setIsTransactionPending(false)
        toast.error('Failed to add lore entry: ' + err.message)
        return
      }

      setCurrentAction('')
      setIsTransactionPending(false)
    },
    [cattoAttributesContract, selectedCatto]
  )
  
  const generateLoadingModal = (title, message, isLoading) => {
    return (
      <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">{title}</h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{message}</p>
                    {isLoading ? (<p><FontAwesomeIcon icon="circle-notch" spin className="mr-2" /></p>) : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  useEffect(() => {
    if (web3Provider?.on) {
      const handleAccountsChanged = (accounts) => {
        window.location.reload()
      }
      
      const handleChainChanged = (chainId) => {
        window.location.reload()
      }
      
      const handleDisconnect = (error) => {
        window.location.reload()
      }
      
      window.ethereum.on('accountsChanged', handleAccountsChanged)
      window.ethereum.on('chainChanged', handleChainChanged)
      window.ethereum.on('disconnect', handleDisconnect)
      
      return () => {
        if (web3Provider.removeListener) {
          window.ethereum.removeListener('accountsChanged', handleAccountsChanged)
          window.ethereum.removeListener('chainChanged', handleChainChanged)
          window.ethereum.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [web3Provider])
  
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet()
    }
  }, [connectWallet])
  
  useEffect(() => {
    let cattoIDs = []
    if (networkID === process.env.REACT_APP_CHAIN_ID && mintContract && tokenContract && cattoAttributesContract && cattoExpeditionContract) {
      // get owner cattos
      mintContract.getOwnerTokenIDs(signerAddress)
        .then(resp => {
          cattoIDs = resp.map(item => { return item.toString() })
          return cattoExpeditionContract.listOwnerStakedCattos(signerAddress)
        })
        .then(resp => {
          const expCattos = resp.map(item => { return item.toString() })
          cattoIDs = [...cattoIDs, ...expCattos]
          setUserCattos(cattoIDs)
          setExpeditionCattos(expCattos)
          const fetchAttr = []
          cattoIDs.forEach(id => {
            fetchAttr.push(cattoAttributesContract.getCattoAttributes(id))
          })
          return Promise.all(fetchAttr)
        })
        .then(resp => {
          const cattoAttributesArr = resp.map(item => {
            return {
              name: item[0].name,
              bio: item[0].bio,
              level: item[0].level.toString(),
              unspentExp: item[0].unspentExp.toString(),
              accumulatedExp: item[0].accumulatedExp.toString(),
              expToNextLevel: item[1].toString(),
              levelExpRequirement: utils.calculateRequiredExpForLevelUp(item[0].level).toString(),
              levelUpCost: item[2],
            }
          })
          const cattoAttributesMap = {}
          cattoIDs.forEach((id, idx) => {
            cattoAttributesMap[id] = cattoAttributesArr[idx]
          })
          setCattosAttributes(cattoAttributesMap)
          return tokenContract.calculateAirdropAmt(cattoIDs)
        })
        .then(resp => {
          setUserTokenAirdrop(resp)
        })
        .catch(err => {
          console.log(err)
          toast.error('Failed to retrieve owned Cattos info')
        })
      // get owner token balance
      tokenContract.balanceOf(signerAddress)
        .then(resp => {
          setUserTokens(resp)
        })
        .catch(err => {
          toast.error('Failed to retrieve token balance')
        })
      // get expedition contract approval status in Catto contract
      mintContract.isApprovedForAll(signerAddress, process.env.REACT_APP_EXPEDITION_CONTRACT)
        .then(resp => {
          setIsExpeditionApprovedInCatto(resp)
        })
        .catch(err => {
          toast.error('Failed to retrieve expedition Catto approval status')
        })
      // get $katsu contract approval status
      tokenContract.allowance(signerAddress, process.env.REACT_APP_ATTRIBUTES_CONTRACT)
        .then(resp => {
          if (resp.toString() !== '0') {
            setIsTokenApproved(true)
          }
        })
        .catch(err => {
          toast.error('Failed to retrieve $KATSU approval status')
        })
    }
  }, [networkID, signerAddress, mintContract, tokenContract, cattoAttributesContract, cattoExpeditionContract])
  
  return (
    <div className="expedition-page min-h-screen">
      <aside className="site-msg bg-primary">
        <div className="container mx-auto py-1 text-white text-center">
          Expeditions & personifications are in BETA, please go to <a className="underline" href="https://discord.gg/tQvBanhQqu" target="_blank" rel="noreferrer">Discord</a> for support
        </div>
      </aside>
      
      <section className="account-details bg-complementary">
        <div className="container mx-auto py-3 text-white">
          {web3Provider ? (
            <div className="flex flex-row flex-wrap justify-between items-center connected">
              <div className="address-details">
                <button className="bg-light-base text-888 rounded px-3 py-1" type="button" onClick={disconnectWallet}>Disconnect</button>
                {networkID === process.env.REACT_APP_CHAIN_ID ? (
                  <span className="ml-3">{signerAddress}</span>
                ) : (
                  <span className="ml-3">
                    <span className="mr-3">Please switch to Fantom Mainnet</span>
                    <button className="bg-primary text-white rounded px-3 py-1" type="button" onClick={switchToMainnet}>Switch</button>
                  </span>
                )}
              </div>
              <div className="token-balance">
                <button className="bg-light-base text-888 rounded px-3 py-1" type="button">$KATSU: {ethers.utils.formatEther(userTokens)}</button>
                {ethers.BigNumber.isBigNumber(userTokenAirdrop) && userTokenAirdrop.gt(0) ? (
                  <button className={expeditionCattos.length > 0 ? 'bg-primary text-white rounded px-3 py-1 ml-3 has-tooltip relative' : 'bg-primary text-white rounded px-3 py-1 ml-3'} type="button" onClick={claimAirdrop} disabled={isTransactionPending}>
                    {expeditionCattos.length > 0 ? (<span className="tooltip rounded shadow-lg p-1 bg-gray-50 text-888 mt-8 text-sm">Will not be able to claim full amount as you have Catto on expedition</span>) : '' }
                    {isTransactionPending ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}Claim $KATSU Airdrop: {ethers.utils.formatEther(userTokenAirdrop)}
                  </button>
                ) : ''}
              </div>
            </div>
          ) : (
            <div className="text-center disconnected">
              <button type="button" onClick={connectWallet}>Connect</button>
            </div>
          )}
        </div>
      </section>
      
      <section className="expedition-dashboard">
        <div className="container mx-auto">
  
          <div className="grid grid-cols-4 gap-4 text-333">
            <div className="sidebar col-span-1 bg-light-base min-h-screen text-center px-6 py-5">
              <div className="title text-lg text-888 font-title mb-6">My Cattos</div>
              <div className="cattos-list">
                {
                  userCattos.length > 0 && cattosAttributes ? (
                    userCattos.map(catto => {
                      return (
                        <figure className={selectedCatto === catto ? 'cursor-pointer mb-4 pt-5 pb-3 bg-light-base-2' : 'cursor-pointer mb-4 pt-5 pb-3'} key={catto} onClick={() => {handleCattoSelect(catto)}}>
                          <img className="mb-3 w-6/12 mx-auto" src={process.env.REACT_APP_IMAGE_URL + catto + '.png'}  alt="" />
                          <figcaption>
                            <p>#{catto}{cattosAttributes[catto].name ? ' — ' + cattosAttributes[catto].name : '' } </p>
                          </figcaption>
                        </figure>
                      )
                    })
                  ) : ''
                }
              </div>
            </div>
            <div className="col-span-3">
              <div className="dashboard-main px-6 py-5 min-h-full">
                {selectedCatto && cattosAttributes && cattosAttributes[selectedCatto] && cattosCurrentExpedition && cattosCurrentExpedition[selectedCatto] ? (
                  <>
                    <div className="catto-details mt-5 border-b pb-8 mb-8">
                      <div className="grid grid-cols-4 gap-8 text-333">
                        <figure className="pfp col-span-1">
                          <img className="mb-3 w-full" src={process.env.REACT_APP_IMAGE_URL + selectedCatto + '.png'}  alt="" />
                          <figcaption className="text-center">
                            {
                              cattosCurrentExpedition[selectedCatto].isActive ? (
                                <span className="bg-primary text-white text-xs rounded-3xl px-2 py-1">Expedition</span>
                              ) : (
                                <span className="bg-complementary text-white text-xs rounded-3xl px-2 py-1">Available</span>
                              )
                            }
                          </figcaption>
                        </figure>
      
                        <div className="dashboard-header col-span-3">
                          <div className="catto-details pb-8">
                            <p>#{selectedCatto}{cattosAttributes[selectedCatto].name ? ' — ' + cattosAttributes[selectedCatto].name : '' }</p>
                            <p><span className="text-888">Current Level:</span> {cattosAttributes[selectedCatto].level}</p>
                            <p><span className="text-888">Experience:</span> {cattosAttributes[selectedCatto].unspentExp} / {cattosAttributes[selectedCatto].levelExpRequirement}</p>
                            <p><span className="text-888">Level Up Cost:</span> {ethers.utils.formatEther(cattosAttributes[selectedCatto].levelUpCost)} $KATSU</p>
                            <p><span className="text-888">Bio:<br/></span> {cattosAttributes[selectedCatto].bio ? cattosAttributes[selectedCatto].bio : '-' }</p>
                          </div>
        
                          <div className="attribute-actions">
                            <Link to={`/ftm/cattos/${selectedCatto}`} className="bg-light-base text-888 rounded px-5 py-2 mb-3 inline-block">View Lore</Link>
                            <br/>
                            {!isTokenApproved ? (
                              <button className="bg-primary text-white rounded px-5 py-2 mr-3" type="button" onClick={approveKatsuToken} disabled={isTokenApproved || isTransactionPending}>
                                {isTransactionPending ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}Approve $KATSU Spend
                              </button>
                            ) : '' }
                            {!cattosCurrentExpedition[selectedCatto].isActive ? (
                              <>
                                <button className="bg-primary text-white rounded px-5 py-2 mr-3" type="button" onClick={levelUp} disabled={
                                  !isTokenApproved || isTransactionPending ||
                                  ethers.BigNumber.from(cattosAttributes[selectedCatto].unspentExp).lt(cattosAttributes[selectedCatto].levelExpRequirement) ||
                                  userTokens.lt(cattosAttributes[selectedCatto].levelUpCost)
                                }>
                                  {isTransactionPending ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}Level Up
                                </button>
                                {ethers.BigNumber.from(cattosAttributes[selectedCatto].level).gte(3) ? (
                                  <button className="bg-primary text-white rounded px-5 py-2 mr-3" type="button" onClick={() => { handleActionIntent('naming') }} disabled={
                                    !isTokenApproved || isTransactionPending ||
                                    (cattosAttributes[selectedCatto].name === "" && userTokens.lt(ethers.utils.parseEther('500'))) ||
                                    (cattosAttributes[selectedCatto].name !== "" && userTokens.lt(ethers.utils.parseEther('1000')))
                                  }>Name Catto</button>
                                ) : ''}
                                {ethers.BigNumber.from(cattosAttributes[selectedCatto].level).gte(4) ? (
                                  <button className="bg-primary text-white rounded px-5 py-2 mr-3" type="button" onClick={() => { handleActionIntent('bio') }} disabled={
                                    !isTokenApproved || isTransactionPending ||
                                    userTokens.lt(ethers.utils.parseEther('1000'))
                                  }>Write Bio</button>
                                ) : ''}
                                {ethers.BigNumber.from(cattosAttributes[selectedCatto].level).gte(5) ? (
                                  <button className="bg-primary text-white rounded px-5 py-2" type="button" onClick={() => { handleActionIntent('lore') }} disabled={
                                    !isTokenApproved || isTransactionPending ||
                                    userTokens.lt(ethers.utils.parseEther('1000'))
                                  }>Add Lore Entry</button>
                                ) : ''}
                              </>
                            ) : ''}
                            
                            <div className="action-forms mt-3">
                              {currentAction === 'naming' ? (
                                <div className="catto-naming">
                                  <input type="text" defaultValue='' id="cattoName" placeholder="Name / Rename Catto" className="mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" />
                                  <button className="bg-complementary text-white rounded px-5 py-2 ml-2" type="button" onClick={handleNameCatto} disabled={!isTokenApproved || isTransactionPending}>Confirm</button>
                                </div>
                              ) : ''}
                              {currentAction === 'bio' ? (
                                <div className="catto-set-bio">
                                  <input type="text" defaultValue='' id="cattoBio" placeholder="Bio" className="mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" />
                                  <button className="bg-complementary text-white rounded px-5 py-2 ml-2" type="button" onClick={handleBio} disabled={!isTokenApproved || isTransactionPending}>Confirm</button>
                                </div>
                              ) : ''}
                              {currentAction === 'lore' ? (
                                <div className="catto-set-bio">
                                  <input type="text" defaultValue='' id="cattoLoreEntry" placeholder="Lore entry" className="mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" />
                                  <button className="bg-complementary text-white rounded px-5 py-2 ml-2" type="button" onClick={handleAddLore} disabled={!isTokenApproved || isTransactionPending}>Confirm</button>
                                </div>
                              ) : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="catto-expedition relative">
                      <div className="relative z-10">
                        <div className="expedition-actions text-center z-10">
                          {!isExpeditionApprovedInCatto ? (
                            <button className="bg-complementary text-white rounded px-5 py-2 mr-3" type="button" onClick={approveExpeditionInCatto} disabled={isExpeditionApprovedInCatto || isTransactionPending}>
                              {isTransactionPending ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}Approve
                            </button>
                          ) : '' }
                          {!cattosCurrentExpedition[selectedCatto].isActive ? (
                            <button className="bg-complementary text-white rounded px-5 py-2" type="button" onClick={startExpedition} disabled={!isExpeditionApprovedInCatto || isTransactionPending}>
                              {isTransactionPending ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}Start Expedition
                            </button>
                          ) : '' }
                        </div>
  
                        {cattosCurrentExpedition[selectedCatto].isActive ? (
                          <div className="expedition-timeline text-center z-10">
                            <p className="countdown font-title text-xl text-888"><Countdown date={moment.unix(cattosCurrentExpedition[selectedCatto].endTimestamp).toDate()} zeroPadTime={2} renderer={
                              ({ hours, minutes, seconds, completed }) => {
                                return <span className="bg-light-base px-3 py-2">Expeditions Ends In: {hours} hour(s) : {minutes} minute(s) : {seconds} second(s) </span>;
                              }
                            } /></p>
                            
                            <div className="timeline py-5">
                              <div>
                                <div>Sent for expedition</div>
                              </div>
                              {cattosCurrentExpedition[selectedCatto].encounterableAddr === ethers.constants.AddressZero ? (
                                <div>
                                  <div className="divider" />
                                  <div>A smooth expedition</div>
                                </div>
                              ) : (
                                <>
                                  {cattosCurrentExpedition[selectedCatto].encounterableType === '1' ? (
                                    <div>
                                      <div className="divider" />
                                      <figure>
                                        <img className="mx-auto" src={cattosCurrentExpedition[selectedCatto].encounterableImg} alt="" />
                                        <figcaption className="mt-2">
                                          Encountered companion<br/>{encounterableMap[cattosCurrentExpedition[selectedCatto].encounterableAddr].name} #{cattosCurrentExpedition[selectedCatto].encounterableId}
                                        </figcaption>
                                      </figure>
                                    </div>
                                  ) : ''}
                                  {cattosCurrentExpedition[selectedCatto].encounterableType === '2' ? (
                                    <>
                                      <div>
                                        <div className="divider" />
                                        <figure>
                                          <img className="mx-auto" src={cattosCurrentExpedition[selectedCatto].encounterableImg} alt="" />
                                          <figcaption className="mt-2">
                                            Encountered foe<br/>
                                            {encounterableMap[cattosCurrentExpedition[selectedCatto].encounterableAddr].name} #{cattosCurrentExpedition[selectedCatto].encounterableId}<br/>
                                            <span className="inline-block bg-primary text-white px-2 py-1 my-1">Foe Level: {cattosCurrentExpedition[selectedCatto].encounterableLvl}</span> vs&nbsp;
                                            <span className="inline-block bg-complementary text-white px-2 py-1 my-1">Catto Level: {cattosAttributes[selectedCatto].level}</span><br/>
                                            {ethers.BigNumber.from(cattosCurrentExpedition[selectedCatto].encounterableLvl).gte(cattosAttributes[selectedCatto].level) ? (
                                              <span className="inline-block bg-red-500 text-white px-2 py-1 my-1">Battle Result: Lose</span>
                                            ) : (
                                              <span className="inline-block bg-green-500 text-white px-2 py-1 my-1">Battle Result: Win</span>
                                            )}
                                          </figcaption>
                                        </figure>
                                      </div>
  
                                      {ethers.BigNumber.from(cattosCurrentExpedition[selectedCatto].encounterableLvl).gte(cattosAttributes[selectedCatto].level) ? (
                                        <div>
                                          <div className="divider" />
                                          <div>
                                            <button className="bg-complementary text-white rounded px-5 py-2" type="button" onClick={hireMercenary} disabled={!isExpeditionApprovedInCatto || isTransactionPending}>
                                              {isTransactionPending ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}Hire Mercenary
                                            </button>
                                          </div>
                                        </div>
                                      ) : ''}
  
                                      {cattosCurrentExpedition[selectedCatto].hiredMercenary ? (
                                        <div>
                                          <div className="divider" />
                                          <figure>
                                            <img className="mx-auto" src={cattosCurrentExpedition[selectedCatto].mercenaryImg} alt="" />
                                            <figcaption className="mt-2">
                                              Hired mercenary<br/>
                                              {encounterableMap[cattosCurrentExpedition[selectedCatto].mercenaryAddr].name} #{cattosCurrentExpedition[selectedCatto].mercenaryId}<br/>
                                              <span className="inline-block bg-primary text-white px-2 py-1 my-1">Foe Level: {cattosCurrentExpedition[selectedCatto].encounterableLvl}</span> vs&nbsp;
                                              <span className="inline-block bg-complementary text-white px-2 py-1 my-1">
                                                Combine Level: {ethers.BigNumber.from(cattosAttributes[selectedCatto].level).add(cattosCurrentExpedition[selectedCatto].mercenaryLvl).toString()}
                                              </span><br/>
                                              {ethers.BigNumber.from(cattosCurrentExpedition[selectedCatto].encounterableLvl).gte(ethers.BigNumber.from(cattosAttributes[selectedCatto].level).add(cattosCurrentExpedition[selectedCatto].mercenaryLvl)) ? (
                                                <span className="inline-block bg-red-500 text-white px-2 py-1 my-1">Final Battle Result: Lose</span>
                                              ) : (
                                                <span className="inline-block bg-green-500 text-white px-2 py-1 my-1">Final Battle Result: Win</span>
                                              )}
                                            </figcaption>
                                          </figure>
                                        </div>
                                      ) : ''}
                                    </>
                                  ) : ''}
                                </>
                              )}
                              {moment().isAfter(moment.unix(cattosCurrentExpedition[selectedCatto].endTimestamp)) ? (
                                <>
                                  <div>
                                    <div className="divider" />
                                    <div>
                                      Expedition completed<br/>
                                      <span className="inline-block bg-primary text-white px-2 py-1 my-1">Exp. Gained: {cattosCurrentExpedition[selectedCatto].earnedExp}</span><br/>
                                      <span className="inline-block bg-primary text-white px-2 py-1 my-1">Earn $KATSU: {cattosCurrentExpedition[selectedCatto].earnedKatsu}</span>
                                    </div>
                                  </div>
                                  <div className="pb-5">
                                    <div className="divider" />
                                    <div>
                                      <button className="bg-complementary text-white rounded px-5 py-2" type="button" onClick={endExpedition} disabled={!isExpeditionApprovedInCatto || isTransactionPending}>
                                        {isTransactionPending ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}End Expedition
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : ''}
                            </div>
                          </div>
                        ) : '' }
                      </div>
                      
                      <div className="bg-expedition-cover absolute inset-0 z-0 opacity-20" style={{
                        background: 'url(/img/cattokatsu-8bit-concept.jpeg) no-repeat',
                        backgroundSize: 'cover'
                      }} />
                    </div>
                  </>
                ) : (<p>Please select a Catto</p>) }
              </div>
            </div>
          </div>
          
        </div>
      </section>
  
      {isTransactionPending ? generateLoadingModal('Transaction In-progress...', 'Please wait while the transaction gets confirmed on the blockchain. It could take up to a few minutes depending on blockchain traffic conditions', true) : ''}
    </div>
  );
}

export default ExpeditionPage;
