function About() {
  return (
    <div id="about" className="section-about">
      <div className="container flex flex-wrap justify-between mx-auto py-10">
        <h2 className="text-complementary text-5xl text-center">About</h2>
        <div className="grid grid-cols-0 md:grid-cols-2 gap-4 items-center">
          <article className="text-xl text-888">
            <p>Catto Katsu is a collection of 10,000 fun, cute, and relatable cats scattered across the <span className="bg-primary text-white">Fantom</span>, <span className="bg-primary text-white">Ethereum</span>, and <span className="bg-primary text-white">Harmony One</span> chains. It is one of the first NFT collection that aims to be cross-chain.</p>
            <p>The NFT art aims to be high-quality, simple, and meme-able. All the 234 individual traits across 9 different categories are hand-drawn, and the final piece is algorithmically generated.</p>
            <p>The project aims to experiment with a unique treasury and adoption mechanism, that could stabilise the floor price over time.</p>
            <p>Total Cattos: <span className="text-555">10,000</span></p>
            <p>Chain Distribution: <span className="text-555">Fantom - 2500, Ethereum - 5000, Harmony One - 2500</span></p>
            <p>Fantom Mint Details: <span className="text-555">1 Dec 2021, 6 AM UTC / 2 PM SGT /  1 AM EST, 70 FTM / Catto</span>.</p>
  
            <a href="https://docs.cattokatsu.com" target="_blank" rel="noreferrer" className="uppercase text-white text-3xl bg-complementary px-10 py-5 rounded-lg inline-block">White Paper</a>
          </article>
          <figure>
            <img srcSet="/img/about-hero.png 1x, /img/about-hero@2x.png 2x" alt="About" className="max-h-100" />
          </figure>
        </div>
      </div>
    </div>
  );
}

export default About;
