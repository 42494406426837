import {BigNumber} from 'ethers'

const utils = {
  
  normaliseIpfsUrl: url => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url
    }
    
    const ipfsParts = url.split('ipfs://')
    if (ipfsParts.length !== 2) {
      return url
    }
    
    return `https://ipfs.io/ipfs/${ipfsParts[1]}`
  },
  
  calculateRequiredExpForLevelUp: currentLvl => {
    if (currentLvl.lte(50)) {
      return currentLvl.add(1).pow(2).sub(currentLvl)
    }
    return BigNumber.from(2500).add(1).pow(2)
  } ,
  
}

export default utils
