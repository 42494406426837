import './CattoPage.css'
import {useEffect, useState} from 'react'
import { useParams} from 'react-router-dom'
import {ethers} from 'ethers'
import { toast } from 'react-toastify'
import utils from '../../utils/utils'
import CattoKatsuContract from '../../contracts/CattoKatsu.json'
import CattoAttributesContract from '../../contracts/CattoAttributes.json'

function CattoPage() {
  const {cattoId} = useParams()
  
  // const [web3Provider, setWeb3Provider] = useState(null)
  // const [mintContract, setMintContract] = useState(null)
  // const [cattoAttributesContract, setCattoAttributesContract] = useState(null)
  // const [metadata, setMetadata] = useState(null)
  const [attributes, setAttributes] = useState(null)
  const [loreEntries, setLoreEntries] = useState([])
  
  useEffect(() => {
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CHAIN_RPC)
    const mintContractInst = new ethers.Contract(process.env.REACT_APP_MINT_CONTRACT, CattoKatsuContract.abi, provider)
    const attrContractInst = new ethers.Contract(process.env.REACT_APP_ATTRIBUTES_CONTRACT, CattoAttributesContract.abi, provider)
    
    mintContractInst.tokenURI(cattoId)
      // .then(resp => {
      //   return fetch(resp)
      // })
      // .then(resp => {
      //   return resp.json()
      // })
      // .then(resp => {
      //   setMetadata(resp)
      // })
      .catch(err => {
        toast.error('Failed to retrieve Catto metadata')
      })
    
    attrContractInst.getCattoAttributes(cattoId)
      .then(resp => {
        const attr = {
          name: resp[0].name,
          bio: resp[0].bio,
          level: resp[0].level.toString(),
          unspentExp: resp[0].unspentExp.toString(),
          accumulatedExp: resp[0].accumulatedExp.toString(),
          expToNextLevel: resp[1].toString(),
          levelExpRequirement: utils.calculateRequiredExpForLevelUp(resp[0].level).toString(),
          levelUpCost: resp[2],
        }
        setAttributes(attr)
      })
      .catch(err => {
        toast.warning('Failed to retrieve Catto attributes')
      })
  
    attrContractInst.getCattoFullLore(cattoId)
      .then(resp => {
        const loreEntries = resp.reverse()
        setLoreEntries(loreEntries)
      })
      .catch(err => {
        toast.warning('Failed to retrieve Catto lore entries')
      })
  
    // setWeb3Provider(provider)
    // setMintContract(mintContractInst)
    // setCattoAttributesContract(attrContractInst)
  }, [cattoId])
  
  return (
    <div className="catto-page min-h-screen">
      <div className="container mx-auto">
        
        <div className="header mb-8 pb-5">
          <div className="grid grid-cols-4 gap-8 text-333 mt-8">
            <figure className="catto-pfp col-span-1">
              <img className="mb-3 w-full" src={process.env.REACT_APP_IMAGE_URL + cattoId + '.png'}  alt="" />
            </figure>
  
            {attributes ? (
              <div className="catto-details col-span-3">
                <p>#{cattoId}{attributes.name ? ' — ' + attributes.name : '' }</p>
                <p><span className="text-888">Current Level:</span> {attributes.level}</p>
                <p><span className="text-888">Experience:</span> {attributes.unspentExp} / {attributes.levelExpRequirement}</p>
                <p><span className="text-888">Level Up Cost:</span> {ethers.utils.formatEther(attributes.levelUpCost)} $KATSU</p>
                <p><span className="text-888">Bio:<br/></span> {attributes.bio ? attributes.bio : '-' }</p>
              </div>
            ) : ''}
          </div>
        </div>
        
        <div className="catto-lores text-center text-555">
          <h2 className="font-heading text-4xl text-888">Catto Lore</h2>
          
          {loreEntries.length > 0 ? (
            <ul className="mx-auto" style={{maxWidth: '760px'}}>
              {loreEntries.map((entry, idx) => {
                return (
                  <li key={idx} className="mb-6 border-b last-of-type:border-0">
                    <p>#{idx + 1}: {entry}</p>
                  </li>
                )
              })}
            </ul>
          ) : (
            <p>No lore found</p>
          )}
        </div>
        
      </div>
    </div>
    
  );
}

export default CattoPage;
